// a,
// a:hover,
// a:focus {
//     color: inherit;
//     text-decoration: none;
//     transition: all 0.3s;
// }

label {
  margin-bottom: 0.3rem;
}
.clickable {
  cursor: pointer;
}

html, body, #root, #app, .page {
  height: 100%;
  width: 100%;
}

.h-auto {
  height: auto !important;
}

.hidden {
  display: none !important;
}

.rotate-180 {
  @include rotate-and-animate(180);
}

.border-left-primary {
  border-left: solid 5px theme-color('primary');
  padding-left: 1em;
}