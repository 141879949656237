@mixin position-sticky {
  position: -webkit-sticky;
  position: -moz-sticky;
  position: -ms-sticky;
  position: -o-sticky;
  position: sticky;
}

@mixin display-flex {
  display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
  display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
  display: -ms-flexbox; /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Chrome */
  display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin flex($flex) {
  -webkit-box-flex: $flex;
  -ms-flex: $flex;
  flex: $flex;
}

@mixin flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

@mixin flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

@mixin flex-column {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

@mixin flex-column-reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

@mixin flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap
}

@mixin justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

@mixin justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@mixin justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@mixin justify-space-around {
  -webkit-justify-content: space-around;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

@mixin justify-space-between {
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

@mixin align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

@mixin align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

@mixin align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

@mixin align-stretch {
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

@mixin align-self-start {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
}

@mixin align-self-center {
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

@mixin align-self-end {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
}

@mixin flex-center-both {
  @include justify-center;
  @include align-center;
}

@mixin box-shadow {
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.08);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.08);
  box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.08);
}

@mixin portlet-container {
  background-color: #fff;
  -webkit-box-shadow: 0 1px 6px rgba(57,73,76,0.35);
    box-shadow: 0 1px 6px rgba(57,73,76,0.35);
}

@mixin animate-all($seconds) {
  -webkit-transition: all $seconds; /* Safari prior 6.1 */
  transition: all $seconds;
}


@mixin rotate($deg: 90){
  $sDeg: #{$deg}deg;

  -webkit-transform: rotate($sDeg);
  -moz-transform: rotate($sDeg);
  -ms-transform: rotate($sDeg);
  -o-transform: rotate($sDeg);
  transform: rotate($sDeg);
}

@mixin rotate-and-animate($deg: 90){
  $sDeg: #{$deg}deg;

  -webkit-transform: rotate($sDeg);
  -moz-transform: rotate($sDeg);
  -ms-transform: rotate($sDeg);
  -o-transform: rotate($sDeg);
  transform: rotate($sDeg);

  @include animate-all(0.3s);
}