.main-content {
  overflow-x: scroll;
  padding: 20px;
  margin-left: 0;
  height: 100vh;
  width: 100%;
  nav.navbar {
    width: 100%;

    .navbar-brand {
      img {
        width: 40px;
      }
    }
  }
}

.footer {
  justify-content: center;
  text-align: center;
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 100%;
  padding: 5px;

  p {
    margin: 1px;
  }
}